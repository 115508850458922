<template>
  <div class="checkpayment">
    <van-nav-bar
      title="收银台"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="payment_money">
      <van-cell :border="false">
        <template #title>
          <span>&yen;</span>{{money}}
        </template>
      </van-cell>
    </div>
    <div class="ordersn" v-if="false">
      <van-cell :border="false">
        <template #title>
          订单编号：
        </template>
        <template #default>
          {{ordersn}}
        </template>
      </van-cell>
    </div>
    <div class="check_paytpe">
      <van-radio-group v-model="paytype">
        <van-cell-group :border="false" v-show="showWxPay == '1'">
          <van-cell :border="false" title="微信支付" clickable @click="changepaytype('21')">
            <template #right-icon>
              <van-radio checked-color="#07c160" name="21" />
            </template>
          </van-cell>
          <van-cell title="余额支付" v-show="showYuePay == '1'" clickable @click="changepaytype('1')">
            <template #right-icon>
              <van-radio checked-color="#07c160" name="1" />
            </template>
          </van-cell>
          <van-cell :border="false" v-if="false" title="支付宝支付" clickable @click="changepaytype('2')">
            <template #right-icon>
              <van-radio checked-color="#07c160" name="2" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
    <div class="paybtn" v-if="paytype==21">
      <van-notice-bar
        wrapable
        v-if="payCode==100000 ? false : true"
        :scrollable="false"
        :text="errorText"
      />
      <van-button
        block
        round
        type="primary"
        :loading="btnloading || payCode != 100000 ? true : false"
        @click="toPay"
      >
        付款
      </van-button>
    </div>
    <div class="paybtn" v-if="paytype==1">
      <van-button
        block
        round
        type="primary"
        :loading="btnloading"
        @click="toPay"
      >
        付款
      </van-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "CheckPayment",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      paytype: "21", // 支付类型 1为余额支付 2在线支付 3 货到付款 11 后台付款 21 微信支付 22 支付宝支付 23 银联支付
      ordersn: "", // 订单号
      money: 0, // 订单金额
      orderid: "", // 订单id
      param: [], // 支付参数
      shareid: 0,
      merchid: 0,
      payCode: "",
      errorText: "加载中...",
      btnloading: false, // 
      mid: "", // 用户id
      openid: "", 
      payurl : "https://api.midbest.cn/web/pay/prepayid",
      showWxPay: '0',
      showYuePay: '0',
    }
  },
  mounted() {
    this.ordersn = localStorage.getItem("ordersn");
    this.money = localStorage.getItem("money");
    this.orderid = localStorage.getItem("orderid");
    this.mid = localStorage.getItem("DK_MEMBERID");
    this.openid = localStorage.getItem("DK_NEW_OPENID");
    if (!this.openid && window.isWeixin()) {
      this.code = this.$route.query.code ? this.$route.query.code : null;
      if (this.code) {
        this.getwxlog(this.code);
      }else {
        this.getopenid();
      }
    }
    window.payOk = this.payOk; // 支付成功 供外部调用
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "收银台");
    this.init();
  },
  methods: {
    init() {
      this.getWXpayParam();
    },
    /**
     * 微信code值
     */
    getopenid() {
      let _that = null;
      _that = this;
      /* 以下下是获取openID 以及判断用户是否在微信中打开页面 */

      /* 在微信中打开页面 */
      if (!this.openid && window.isWeixin()) { 
        const wxAuthUrl = 
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx65fad56bebb47a1e&redirect_uri=" + encodeURIComponent(window.location.href) + "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";

          // 传到后台得到openid, 其中access_token 2小时过期
          if (_that.code == null || _that.code === "") {
            window.location.href = wxAuthUrl; // [自刷新当前页]
          }
      } else {
        // localStorage存在openid || 本页面不在公众号中打开
        console.log("localStorage存在openid || 本页面不在公众号中打开");
      }
    },
    /**
     * 获取微信openID
     */
    getwxlog(code) {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/wetch/wx_new_oauth2",
          _that.$qs.stringify({
            code: code
          })
        )
        .then(function(response) {
          if (response.data) {
            localStorage.setItem("DK_NEW_OPENID", response.data); // 获得用户的openid
            _that.openid = response.data;
            /* 保存openID */
            // _that.saveOpenid();
            _that.$router.replace({ name: "CheckPayment", query: { shareid: _that.shareid, merchid: _that.merchid } });
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    // 获取微信支付参数
    getWXpayParam() {
      let _that = null;
      _that = this;
      this.payurl = "https://api.midbest.cn/web/payv1/prepayid";
      
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.payurl,
          _that.$qs.stringify({
            oid: _that.orderid,
            mid: _that.mid,
            openid: _that.openid,
          })
        )
        .then(response => {
          _that.payCode = response.data.code;
          _that.errorText = response.data.msg;
          _that.showWxPay = response.data.data.showWxPay
          _that.showYuePay = response.data.data.showYuePay
          _that.paytype = response.data.data.defaultPayType
          if (response.data.code == 100000) {
            _that.param['21'] = {
              appId: response.data.data.appId,
              timeStamp: response.data.data.timeStamp,
              nonceStr: response.data.data.nonceStr,
              package: response.data.data.package,
              signType: response.data.data.signType,
              paySign: response.data.data.paySign
            }
          }
        })
    },
    // 返回上一页
    onClickLeft() {
      this.$router.go(-1);
    },
    // 返回支付结果
    payOk() {
      this.$toast("支付成功");
      this.$router.replace({ name: "PaySuccess", query: { shareid: this.shareid, merchid: this.merchid } });
    },
    // 改变支付方式
    changepaytype(num) {
      this.paytype = num;
    },
    // 调用支付
    toPay() {
      if (this.paytype == "21") { // 微信支付
        if (this.payCode == 100000) {
          window.onwxpay(this.param['21']); // 调起支付
        }else{
          this.$toast(this.errorText ? this.errorText : "加载中...");
        }
      }else if(this.paytype == "1") { // 余额支付
        this.yuepay();
      } 
    },
    yuepay() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.payurl = "https://api.midbest.cn/web/payv1/credit";
      _that.$axios
        .post(
          _that.payurl,
          _that.$qs.stringify({
            oid: _that.orderid,
            mid: _that.mid,
            openid: _that.openid,
          })
        )
        .then(response => {
          if (response.data.code == 100000) {
            console.log("支付成功");
            _that.$toast("支付成功");
            setTimeout(() => {
              _that.$router.replace({ name: "PaySuccess", query: { shareid: _that.shareid, merchid: _that.merchid } });
            }, 500);
          }else {
            console.log("支付失败");
            _that.$toast(response.data.msg ? response.data.msg : "支付失败");
          }
        })
    }
  }
}
</script>
<style lang="less">
.checkpayment {
  width: 100%;
  height: 100%;
  background-color: #fff;
  .van-nav-bar {
    .van-icon {
      color: #333;
    }
  }
  .ordersn{
    margin-bottom: 10px;
    .van-cell{
      .van-cell__title{
        width: 80px;
      }
      .van-cell__value{
        flex: 1;
      }
    }
  }
  .payment_money {
    margin-top: 26%;
    .van-cell{
      padding: 10px 16px;
      .van-cell__title {
        flex: 1;
        line-height: 60px;
        font-size: 50px;
        font-weight: 500;
        color: #E33B23;
        // font-family: 'WeChat Sans';
        span {
          font-size: 20px;
          margin-right: 5px;
        }
      }
    }
  }
  .check_paytpe{
    .van-cell{
      text-align: left;
      padding: 10px 16px;
      line-height: 30px;
      .van-cell__title {
        flex: 1;
      }
    }
  }
  .paybtn{
    padding: 10px 16px;
    margin-top: 40px;
    .van-notice-bar {
      padding: 2px 10px;
      margin-bottom: 5px;
      text-align: center;
      .van-notice-bar__wrap {
        justify-content: center;
      }
    }
  }
}
</style>